import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../Button";
import { useRef } from "react";

function Navbar() {
  let { t, i18n } = useTranslation();
  let navRef = useRef(null);
  let backdropRef = useRef(null);
  let menuToggleRef = useRef(null);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const closeMenu = () => {
    backdropRef.current.classList.remove("opacity-40");
    backdropRef.current.classList.add("opacity-0");
    backdropRef.current.classList.add("pointer-events-none");
    navRef.current.classList.add("-translate-y-full");
  };

  const openMenu = () => {
    backdropRef.current.classList.remove("opacity-0");
    backdropRef.current.classList.add("opacity-40");
    backdropRef.current.classList.remove("pointer-events-none");
    navRef.current.classList.remove("-translate-y-full");
  };

  return (
    <header className="sticky top-0 z-50 w-full lg:h-16 h-12 shadow-[0_4px_12px_0_rgba(0,0,0,0.08)] bg-white">
      <div className="max-w-screen-xl mx-auto h-full">
        <div className="relative lg:px-[4.5rem] lg:py-3 px-6 py-3 h-full">
          <div className="flex items-center">
            <Link to="/">
              <img
                className="w-[128px] min-w-[128px] lg:w-[200px] lg:min-w-[200px] object-contain"
                src="/asset/logo.svg"
                alt="logo"
              />
            </Link>
            <button className="lg:hidden ml-auto" onClick={openMenu}>
              <img src="/asset/menu.svg" alt="menu"></img>
            </button>
            <div
              ref={backdropRef}
              className="w-screen h-screen bg-black opacity-0 absolute top-0 left-0 transition-all pointer-events-none lg:hidden"
              onClick={closeMenu}
            ></div>
            <nav
              ref={navRef}
              className="flex flex-col -translate-y-full lg:-translate-y-0 transition-all w-screen lg:w-auto absolute top-0 left-0 lg:relative bg-white flex-1 pb-7 lg:pb-0"
            >
              <button
                ref={menuToggleRef}
                className="lg:hidden self-end mt-3 mr-6 mb-2"
                onClick={closeMenu}
              >
                <img src="/asset/dismiss.svg" alt="dismiss"></img>
              </button>
              <ul className="flex flex-col lg:flex-row items-center gap-4 lg:gap-0">
                <li>
                  <Link
                    className="block ml-0 lg:ml-14 p-2 rounded hover:bg-primary-tint"
                    to="/"
                    onClick={closeMenu}
                  >
                    {t("about")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="block ml-0 lg:ml-14 p-2 rounded hover:bg-primary-tint"
                    to="/plans"
                    onClick={closeMenu}
                  >
                    {t("purchase")}
                  </Link>
                </li>
                <li className="flex justify-center lg:justify-end flex-1 w-full lg:w-auto">
                  <div className="group relative flex lg:block">
                    <button className="flex items-center hover:bg-gray-100 p-2 rounded pointer-events-none lg:pointer-events-auto">
                      <img className="mr-2 lg:mr-3" src="/asset/earth.svg" alt="language" />
                      <span className="hidden lg:block">{t("language")}</span>
                    </button>
                    <div className="hidden lg:block absolute h-1 w-full"></div>
                    <div className="flex flex-row lg:flex-col lg:absolute lg:hidden lg:mt-1 bg-white lg:border lg:border-slate-200 rounded lg:group-hover:block">
                      <button
                        className="w-full text-center px-2 lg:px-3 py-2 lg:hover:bg-gray-100 rounded lg:rounded-none border border-transparent focus:border-slate-200 whitespace-nowrap"
                        onClick={changeLanguage.bind(this, "zh-TW")}
                      >
                        繁體中文
                      </button>
                      <button
                        className="w-full text-center px-2 lg:px-3 py-2 lg:hover:bg-gray-100 rounded lg:rounded-none border border-transparent focus:border-slate-200 whitespace-nowrap"
                        onClick={changeLanguage.bind(this, "en")}
                      >
                        English
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
            <Link className="hidden lg:block" to="/plans">
              <Button className="ms-14 whitespace-nowrap" size="sm" theme="primary">
                {t("trypage")}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
