function FeatureCard({ title = "", content = "", imgSrc = "", className = "" }) {
  return (
    <div
      className={`py-6 lg:px-8 lg:py-8 flex flex-col items-center h-fit col-span-1 max-w-[388.5px] ${className}`}
    >
      <img className="h-[4.5rem] w-[4.5rem] mb-10" src={imgSrc} alt="Features 1" />
      <p className="title text-center mb-6">{title}</p>
      <p className="text-neutral-tint text-center lg:text-left">{content}</p>
    </div>
  );
}

export default FeatureCard;
