function Button({
  children,
  size = "",
  theme = "",
  type = "button",
  className = "",
  onClick = () => {},
  ...props
}) {
  let btnClass = ["inline-block", "text-center", "font-bold", "text-base"].concat(
    className.split(" ")
  );

  switch (size) {
    case "lg":
    default:
      btnClass = btnClass.concat("px-9 py-4 leading-[1.125rem] rounded".split(" "));
      break;
    case "sm":
      btnClass = btnClass.concat("px-3 py-2 leading-[1.125rem] rounded-[0.1875rem]".split(" "));
      break;
  }

  switch (theme) {
    case "primary":
    default:
      btnClass = btnClass.concat("bg-primary text-white hover:bg-primary-darken".split(" "));
      break;
    case "outline":
      btnClass = btnClass.concat(
        "bg-transparent text-primary border border-solid border-primary hover:bg-primary-tint".split(
          " "
        )
      );
      break;
    case "neutral":
      btnClass = btnClass.concat("bg-neutral text-white hover:bg-neutral-darken".split(" "));
      break;
  }

  return (
    <button type={type} className={`${btnClass.join(" ")}`} onClick={onClick} {...props}>
      {children}
    </button>
  );
}

export default Button;
