import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CountUp } from "use-count-up";
import React, { useEffect, useRef, useState } from "react";
import Button from "../components/Button";
import FeatureCard from "../components/Landing/FeatureCard";

function Landing() {
  let { t } = useTranslation();
  let [isLandingShow, setIsLandingShow] = useState(false);
  let [isIdeShow, setIsIdeShow] = useState(false);
  let [isHomeworkShow, setIsHomeworkShow] = useState(false);
  let [isTestShow, setIsTestShow] = useState(false);
  let [isCourseShow, setIsCourseShow] = useState(false);
  let [isHomeworkCounting, setIsHomeworkCounting] = useState(false);
  let [isTestCounting, setIsTestCounting] = useState(false);
  let [isCourseCounting, setIsCourseCounting] = useState(false);
  let ideRef = useRef(null);
  let homeworkRef = useRef(null);
  let testRef = useRef(null);
  let courseRef = useRef(null);

  useEffect(() => {
    document.title = "One Judge";
    setIsLandingShow(true);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // IDE
      if (ideRef.current) {
        let ideTop = ideRef.current.getBoundingClientRect().top;
        if (ideTop < window.innerHeight - ideRef.current.clientHeight * 0.75) {
          setIsIdeShow(true);
        }
      }

      // Homework
      if (homeworkRef.current) {
        let homeworkTop = homeworkRef.current.getBoundingClientRect().top;
        if (homeworkTop < window.innerHeight - homeworkRef.current.clientHeight * 0.75) {
          setIsHomeworkShow(true);
          setIsHomeworkCounting(true);
        }
      }

      // Test
      if (testRef.current) {
        let testTop = testRef.current.getBoundingClientRect().top;
        if (testTop < window.innerHeight - testRef.current.clientHeight * 0.75) {
          setIsTestShow(true);
          setIsTestCounting(true);
        }
      }

      // Course
      if (courseRef.current) {
        let courseTop = courseRef.current.getBoundingClientRect().top;
        if (courseTop < window.innerHeight - courseRef.current.clientHeight * 0.75) {
          setIsCourseShow(true);
          setIsCourseCounting(true);
        }
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isIdeShow) {
      let ideImgs = ideRef.current.querySelectorAll("img");
      ideImgs.forEach((img, index) => {
        img.classList.remove("opacity-0");
        img.classList.remove("-translate-x-1/2");
      });
    }

    if (isHomeworkShow) {
      homeworkRef.current.classList.remove("opacity-0");
      homeworkRef.current.classList.remove("translate-y-full");
    }

    if (isTestShow) {
      testRef.current.classList.remove("opacity-0");
      testRef.current.classList.remove("translate-y-full");
    }

    if (isCourseShow) {
      courseRef.current.classList.remove("opacity-0");
      courseRef.current.classList.remove("translate-y-full");
    }
  }, [isIdeShow, isHomeworkShow, isTestShow, isCourseShow]);

  return (
    <>
      <div className="max-w-screen-xl mx-auto mb-8 lg:mb-[120px] mt-8 px-4 lg:mt-14 lg:px-16">
        <div className="relative bg-landing bg-repeat rounded-[1.25rem] p-6 lg:pt-24 lg:pb-28">
          <img
            className={`object-contain absolute top-1/2 ${
              isLandingShow ? "-translate-y-1/2" : "-translate-y-full"
            } left-1/2 -translate-x-1/2 hidden lg:block pointer-events-none transition-all duration-1000 ${
              isLandingShow ? "opacity-100" : "opacity-0"
            }`}
            src="/asset/background_icons.png"
            alt="Background Icon"
          />
          <div
            className={`flex flex-col space-y-8 items-center text-center w-full transition-all duration-1000 ${
              isLandingShow ? "opacity-100" : "opacity-0"
            }`}
          >
            <h1 className="text-white">
              <span className="hidden lg:block">One Judge</span>
              <span className="block lg:max-w-[45rem]">{t("introduce")}</span>
            </h1>
            <p className="text-white w-full lg:max-w-[488px]">{t("moreinroduce")}</p>
            <Link to="/plans">
              <Button size="lg" theme="neutral">
                {t("trypage")}
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="w-full bg-ide mb-14 lg:mb-40 rounded-r-full">
        <div className="mx-auto max-w-screen-xl flex flex-col lg:flex-row items-center gap-6 lg:gap-28 px-7 lg:px-28 py-6 lg:py-[4.5rem] justify-center">
          <h2 className="lg:max-w-[50%]">
            <span className="bg-integration bg-clip-text text-transparent">
              {t("IDE_segment1")}
            </span>
            <span>{t("IDE_segment2")}</span>
          </h2>
          <div ref={ideRef} className="flex gap-6 lg:gap-14">
            <img
              className="h-14 lg:h-[90px] opacity-0 transition-all duration-1000 -translate-x-1/2"
              src="/asset/Visual Studio.png"
              alt="Visual Studio"
            />
            <img
              className="h-14 lg:h-[90px] opacity-0 transition-all duration-1000 -translate-x-1/2 delay-150"
              src="/asset/vscode.svg"
              alt="vscode"
            />
            <img
              className="h-14 lg:h-[90px] opacity-0 transition-all duration-1000 -translate-x-1/2 delay-300"
              src="/asset/Copilot.png"
              alt="Copilot"
            />
          </div>
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto mb-14 lg:mb-40">
        <div className="flex flex-col w-fit mx-auto  lg:flex-row items-start lg:items-center justify-center space-y-10 lg:space-y-0 lg:space-x-28 px-7 lg:px-24">
          <div
            ref={homeworkRef}
            className="flex space-x-4 lg:space-x-8 items-center transition-all duration-1000 translate-y-full opacity-0"
          >
            <img
              className="object-contain h-[4.5rem] w-[4.5rem]"
              src="/asset/icon_data_01.png"
              alt="Icon Data 1"
            />
            <div className="flex flex-col">
              <h1>
                <CountUp end={parseInt(t("homework_times"))} isCounting={isHomeworkCounting} />
                {`+ ${t("homework_timesunit")}`}
              </h1>
              <h1 className="opacity-0 h-0">{`${t("homework_times")} + ${t("homework_timesunit")}`}</h1>
              <p className="caption mt-2">{t("homework")}</p>
            </div>
          </div>

          <div
            ref={testRef}
            className="flex space-x-4 lg:space-x-8 items-center transition-all duration-1000 translate-y-full opacity-0"
          >
            <img
              className="object-contain h-[4.5rem] w-[4.5rem]"
              src="/asset/icon_data_02.png"
              alt="Icon Data 2"
            />
            <div className="flex flex-col">
              <h1>
                <CountUp end={parseInt(t("test_times"))} isCounting={isTestCounting} />
                {`+ ${t("test_timesunit")}`}
              </h1>
              <h1 className="opacity-0 h-0">{`${t("test_times")} + ${t("test_timesunit")}`}</h1>
              <p className="caption mt-2">{t("test")}</p>
            </div>
          </div>

          <div
            ref={courseRef}
            className="flex space-x-4 lg:space-x-8 items-center transition-all duration-1000 translate-y-full opacity-0"
          >
            <img
              className="object-contain h-[4.5rem] w-[4.5rem]"
              src="/asset/icon_data_03.png"
              alt="Icon Data 3"
            />
            <div className="flex flex-col">
              <h1>
                <CountUp end={parseInt(t("course_times"))} isCounting={isCourseCounting} />
                {`+ ${t("course_timesunit")}`}
              </h1>
              <h1 className="opacity-0 h-0">{`${t("course_times")} + ${t("course_timesunit")}`}</h1>
              <p className="caption mt-2">{t("course")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-screen-xl p-4 mb-14 lg:mb-40 hidden">
        {/* hidden */}
        <iframe
          className="mx-auto w-full aspect-video lg:w-1/2 rounded-[0.625rem]"
          src="https://www.youtube.com/embed/ZRtdQ81jPUQ"
          title="One Judge Introduction Video"
        ></iframe>
      </div>

      {/* Web */}
      <div className="mx-auto max-w-screen-xl mb-40 hidden lg:block">
        <div className="flex justify-center gap-20">
          <div className="flex flex-col gap-20">
            <FeatureCard
              className=""
              title={t("features1_title")}
              content={t("features1_content")}
              imgSrc="/asset/icon_features_01.png"
            />
            <FeatureCard
              className=""
              title={t("features3_title")}
              content={t("features3_content")}
              imgSrc="/asset/icon_features_03.png"
            />
            <FeatureCard
              className=""
              title={t("features5_title")}
              content={t("features5_content")}
              imgSrc="/asset/icon_features_04.png"
            />
          </div>
          <div className="flex flex-col gap-20 mt-20">
            <FeatureCard
              className=""
              title={t("features2_title")}
              content={t("features2_content")}
              imgSrc="/asset/icon_features_02.png"
            />
            <FeatureCard
              className=""
              title={t("features4_title")}
              content={t("features4_content")}
              imgSrc="/asset/icon_features_04-1.png"
            />
            <FeatureCard
              className=""
              title={t("features6_title")}
              content={t("features6_content")}
              imgSrc="/asset/icon_features_06.png"
            />
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="mx-auto max-w-screen-xl mb-14 lg:hidden">
        <div className="grid grid-cols-1 px-4 justify-items-center">
          <FeatureCard
            title={t("features1_title")}
            content={t("features1_content")}
            imgSrc="/asset/icon_features_01.png"
          />
          <FeatureCard
            title={t("features2_title")}
            content={t("features2_content")}
            imgSrc="/asset/icon_features_02.png"
          />
          <FeatureCard
            title={t("features3_title")}
            content={t("features3_content")}
            imgSrc="/asset/icon_features_03.png"
          />
          <FeatureCard
            title={t("features4_title")}
            content={t("features4_content")}
            imgSrc="/asset/icon_features_04-1.png"
          />
          <FeatureCard
            title={t("features5_title")}
            content={t("features5_content")}
            imgSrc="/asset/icon_features_04.png"
          />
          <FeatureCard
            title={t("features6_title")}
            content={t("features6_content")}
            imgSrc="/asset/icon_features_06.png"
          />
        </div>
      </div>

      <div className="w-full bg-complementary-tint mb-14 lg:mb-40">
        <div className="px-7 py-6 lg:px-28 lg:py-14 mx-auto max-w-screen-xl flex flex-col space-y-6 lg:space-y-0 items-center lg:flex-row">
          <h1 className="text-center lg:hidden">
            {t("lookforward")}
            <br />
            {t("continue")}
          </h1>
          <h2 className="hidden lg:block w-[324px]">
            {t("lookforward")}
            <br />
            {t("continue")}
          </h2>
          <div className="flex flex-col flex-1 space-y-4 lg:ml-[126px]">
            <div className="flex space-x-6 lg:space-x-10 px-6 py-4 lg:px-14 lg:py-8 bg-white shadow-[0_10px_30px_0_rgba(0,0,0,0.06)]">
              <img
                className="object-contain h-12 lg:h-[4.5rem]"
                src="/asset/icon_new_01.png"
                alt="New 1"
              />
              <div className="flex flex-col">
                <p className="title hidden lg:block mb-6">{t("function1_title")}</p>
                <h2 className="lg:hidden mb-2">{t("function1_title")}</h2>
                <p>{t("function1_content")}</p>
              </div>
            </div>

            <div className="flex space-x-6 lg:space-x-10 px-6 py-4 lg:px-14 lg:py-8 bg-white shadow-[0_10px_30px_0_rgba(0,0,0,0.06)]">
              <img
                className="object-contain h-12 lg:h-[4.5rem]"
                src="/asset/icon_new_02.png"
                alt="New 2"
              />
              <div className="flex flex-col">
                <p className="title hidden lg:block mb-6">{t("function2_title")}</p>
                <h2 className="lg:hidden mb-2">{t("function2_title")}</h2>
                <p>{t("function2_content")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-screen-xl text-center px-7 lg:px-36 mb-14">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <h1 className="mb-8 lg:hidden">{t("partner")}</h1>
          <h2 className="hidden lg:block">{t("partner")}</h2>
          <img
            className="w-[88px] lg:w-[114px] mb-2 lg:mb-0"
            src="/asset/NTUT.png"
            alt="NTUST Logo"
          />
          <img className="w-[245px] lg:w-[358px]" src="/asset/Microslft.png" alt="Microsoft Logo" />
        </div>
      </div>

      <div className="mx-auto max-w-screen-xl mb-14 lg:mb-40">
        <div className="mx-4 lg:mx-16 p-8 lg:px-[120px] lg:py-16 bg-partner rounded-2xl flex flex-col items-center lg:items-start lg:flex-row">
          <div className="flex flex-col items-center mb-6 w-[205px] lg:mb-0">
            <img
              className="object-cover aspect-square rounded-full mb-4 lg:mb-12"
              src="/asset/NTUT.png"
              alt="partner"
            />
            <p className="title text-center mb-2 hidden lg:block">{t("partner_name")}</p>
            <h2 className="mb-1 text-center lg:hidden">{t("partner_name")}</h2>
            <p>{t("belong")}</p>
          </div>

          <div className="flex-1 lg:ml-24">
            <h2 className="mb-2 lg:mb-8">{t("partner_title")}</h2>
            <p className="opacity-80">{t("partner_content")}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
