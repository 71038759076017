import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
  let { t } = useTranslation();

  return (
    <footer className="w-full bg-[#EFF2F4]">
      <div className="max-w-screen-xl mx-auto p-6 lg:px-[120px] lg:pt-[50px] lg:pb-8">
        <div className="flex flex-col lg:flex-row items-center mb- 6 lg:mb-[50px]">
          <img
            className="w-[200px] object-contain mb-6 lg:mb-0 lg:mr-[100px]"
            src="/asset/logo.svg"
            alt="logo"
          />
          <ul className="flex flex-col mb-6 lg:mb-0 lg:flex-row items-center space-y-2 lg:space-y-0 lg:space-x-14">
            <li>
              <Link className="block p-2 rounded hover:bg-primary-tint" to="/">
                {t("about")}
              </Link>
            </li>
            <li>
              <Link className="block p-2 rounded hover:bg-primary-tint" to="/plans">
                {t("purchase")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="border-t border-solid border-[#B8CADB] mb-6 lg:mb-8"></div>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-[160px] mb-10 lg:mb-[4.5rem]">
          <div className="flex-1 flex flex-col space-y-4 lg:space-y-6">
            <p className="title">{t("company")}</p>
            <p className="caption">{t("companyprofile")}</p>
          </div>
          <div className="flex-1 flex flex-col space-y-4 lg:space-y-6">
            <p className="title">{t("contract")}</p>
            <div className="flex items-center">
              <img className="h-[24px] mr-4" src="/asset/icon_footer_person.png" alt="person"></img>
              <p className="caption">{t("ceo")}</p>
            </div>
            <div className="flex items-center">
              <img className="h-[24px] mr-4" src="/asset/icon_footer_phone.png" alt="phone"></img>
              <p className="caption">{t("tel")}</p>
            </div>
            <div className="flex items-center">
              <img className="h-[24px] mr-4" src="/asset/icon_footer_email.png" alt="email"></img>
              <p className="caption">{t("mail")}</p>
            </div>
          </div>
        </div>
        <p className="caption text-center text-[#72787e]">
          Copyright © 2023 HES Technology Inc All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
