import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import Plans from "./pages/Plans";
import Navbar from "./components/layouts/Navbar";
import Footer from "./components/layouts/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="/plans" element={<Plans />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
