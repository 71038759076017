import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import useScript from "../hooks/useScript";

function Plans() {
  let { t } = useTranslation();
  let contractSection = useRef(null);
  let organizationNameRef = useRef(null);
  let organizationNameErrorRef = useRef(null);
  let contactNameRef = useRef(null);
  let contactNameErrorRef = useRef(null);
  let emailRef = useRef(null);
  let emailErrorRef = useRef(null);
  let contentRef = useRef(null);
  let contentErrorRef = useRef(null);
  let formRef = useRef(null);
  let responseRef = useRef(null);

  useScript(
    "https://www.google.com/recaptcha/enterprise.js?render=6LdvsL0nAAAAABqzyAc6FmAMTjqlKU2o9r2jaw_8"
  );

  useEffect(() => {
    document.title = "One Judge - 購買方案";
  }, []);

  const [formData, setFormData] = useState({
    organization_name: "",
    contact_name: "",
    email: "",
    content: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // validate
    let isValid = true;
    if (formData.organization_name === "") {
      organizationNameRef.current.classList.add("border-negative");
      organizationNameErrorRef.current.classList.add("opacity-100");
      isValid = false;
    } else {
      organizationNameRef.current.classList.remove("border-negative");
      organizationNameErrorRef.current.classList.remove("opacity-100");
    }

    if (formData.contact_name === "") {
      contactNameRef.current.classList.add("border-negative");
      contactNameErrorRef.current.classList.add("opacity-100");
      isValid = false;
    } else {
      contactNameRef.current.classList.remove("border-negative");
      contactNameErrorRef.current.classList.remove("opacity-100");
    }

    if (formData.email === "") {
      emailRef.current.classList.add("border-negative");
      emailErrorRef.current.classList.add("opacity-100");
      emailErrorRef.current.innerText = t("errortext");
      isValid = false;
    } else {
      emailRef.current.classList.remove("border-negative");
      emailErrorRef.current.classList.remove("opacity-100");

      const emailRule = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRule.test(formData.email)) {
        emailRef.current.classList.add("border-negative");
        emailErrorRef.current.classList.add("opacity-100");
        emailErrorRef.current.innerText = t("email_error_text");
        isValid = false;
      } else {
        emailRef.current.classList.remove("border-negative");
        emailErrorRef.current.classList.remove("opacity-100");
      }
    }

    if (formData.content === "") {
      contentRef.current.classList.add("border-negative");
      contentErrorRef.current.classList.add("opacity-100");
      isValid = false;
    } else {
      contentRef.current.classList.remove("border-negative");
      contentErrorRef.current.classList.remove("opacity-100");
    }

    if (isValid) {
      window.grecaptcha.enterprise.ready(function () {
        window.grecaptcha.enterprise
          .execute("6LdvsL0nAAAAABqzyAc6FmAMTjqlKU2o9r2jaw_8", { action: "login" })
          .then(function (token) {
            let request = {
              ...formData,
              re_captcha_token: token,
            };

            fetch("https://commonservice.hestechs.com/one-judge/contact", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(request),
            });
          });
      });

      formRef.current.classList.add("hidden");
      responseRef.current.classList.remove("hidden");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const scrollToContractSection = () => {
    if (contractSection.current) {
      contractSection.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      <div className="mx-auto max-w-screen-xl mt-8 lg:mt-28 mb-14 lg:mb-40">
        <div className="grid grid-cols-1 lg:grid-cols-2 px-4 lg:px-16 gap-2 lg:gap-6">
          <div className="flex flex-col border-2 border-neutral border-solid rounded-[1.25rem] p-6 lg:p-12 gap-6 lg:gap-8">
            <h2 className="hidden lg:block">{t("person_auth")}</h2>
            <h1 className="lg:hidden">{t("person_auth")}</h1>
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2">
                <img className="object-contain h-6" src="/asset/advantage-check.png" alt="Check" />
                <p>{t("advantage1")}</p>
              </div>
              <div className="flex items-center gap-2">
                <img className="object-contain h-6" src="/asset/advantage-check.png" alt="Check" />
                <p>{t("advantage2")}</p>
              </div>
              <div className="flex items-center gap-2">
                <img className="object-contain h-6" src="/asset/advantage-check.png" alt="Check" />
                <p>{t("advantage3")}</p>
              </div>
              <div className="flex items-center gap-2">
                <img className="object-contain h-6" src="/asset/advantage-check.png" alt="Check" />
                <p>{t("advantage4")}</p>
              </div>
              <div className="flex items-center gap-2">
                <img className="object-contain h-6" src="/asset/advantage-check.png" alt="Check" />
                <p>{t("advantage5")}</p>
              </div>
            </div>
            <div>
              <h2 className="hidden lg:inline-block mr-1 ">{t("price")}</h2>
              <h1 className="inline-block lg:hidden">{t("price")}</h1>
              <p className="inline-block">/{t("priceunit")}</p>
            </div>
            <Button size="lg" type="primary">
              {t("trypage")}(即將上線)
            </Button>
          </div>

          <div className="flex flex-col border-2 border-neutral border-solid rounded-[1.25rem] p-6 lg:p-12 gap-6 lg:gap-8">
            <h2>{t("team_auth")}</h2>
            <p className="flex-1">{t("teamauth_intro")}</p>
            <Button size="lg" theme="outline" onClick={scrollToContractSection}>
              {t("contract")}
            </Button>
          </div>
        </div>
      </div>

      <div ref={contractSection} className="w-full bg-primary-tint mb-14 lg:mb-40">
        <div className="mx-auto max-w-screen-xl flex flex-col px-10 py-12 gap-6 lg:gap-[4.5rem] justify-center items-center lg:flex-row">
          <div className="flex flex-col gap-6 lg:gap-8 items-center lg:items-start">
            <h1 className="text-center lg:hidden">
              {t("form1")}
              <br />
              {t("form2")}
              <br />
              {t("form3")}
            </h1>
            <h2 className="hidden lg:block">
              {t("form1")}
              <br />
              {t("form2")}
              <br />
              {t("form3")}
            </h2>
            <img
              className="w-[13.25rem] lg:w-[22rem]"
              src="/asset/illustration_contactUs.png"
              alt="contactUs"
            />
            <div className="flex items-center gap-4">
              <img className="w-[44px] aspect-square" src="/asset/NTUT.png" alt="NTUST" />
              <p className="caption text-neutral-tint">
                {t("form4")}
                <br />
                {t("form5")}
              </p>
            </div>
          </div>

          <form
            ref={formRef}
            className="flex flex-col w-full max-w-[446px] p-4 lg:p-0"
            onSubmit={handleSubmit}
          >
            <div className="mb-1 w-full">
              <p className="font-bold mb-2">{t("form_companyname")}</p>
              <input
                id="organization_name"
                name="organization_name"
                className="w-full border border-solid border-neutral-input bg-white focus:border-neutral rounded mb-1 px-4 py-[0.625rem] placeholder:text-neutral-input"
                type="text"
                placeholder={t("form_input")}
                onChange={handleChange}
                ref={organizationNameRef}
              />
              <p ref={organizationNameErrorRef} className="text-xs text-negative opacity-0">
                {t("errortext")}
              </p>
            </div>
            <div className="mb-1">
              <p className="font-bold mb-2">{t("form_name")}</p>
              <input
                id="contact_name"
                name="contact_name"
                className="w-full border border-solid border-neutral-input bg-white focus:border-neutral rounded mb-1 px-4 py-[0.625rem] placeholder:text-neutral-input"
                type="text"
                placeholder={t("form_input")}
                onChange={handleChange}
                ref={contactNameRef}
              />
              <p ref={contactNameErrorRef} className="text-xs text-negative opacity-0">
                {t("errortext")}
              </p>
            </div>
            <div className="mb-1">
              <p className="font-bold mb-2">{t("form_mail")}</p>
              <input
                id="email"
                name="email"
                className="w-full border border-solid border-neutral-input bg-white focus:border-neutral rounded mb-1 px-4 py-[0.625rem] placeholder:text-neutral-input"
                type="text"
                placeholder={t("form_input")}
                onChange={handleChange}
                ref={emailRef}
              />
              <p ref={emailErrorRef} className="text-xs text-negative opacity-0">
                {t("errortext")}
              </p>
            </div>
            <div className="mb-6 lg:mb-10">
              <p className="font-bold mb-2">{t("form_messenge")}</p>
              <textarea
                id="content"
                name="content"
                className="w-full border border-solid border-neutral-input bg-white focus:border-neutral rounded mb-1 px-4 py-[0.625rem] placeholder:text-neutral-input"
                rows="2"
                placeholder={t("form_question")}
                onChange={handleChange}
                ref={contentRef}
              />
              <p ref={contentErrorRef} className="text-xs text-negative opacity-0">
                {t("errortext")}
              </p>
            </div>
            <Button type="submit">{t("submit")}</Button>
          </form>

          <div ref={responseRef} className="flex-col self-start hidden">
            <div className="bg-white px-11 py-10 mb-10">
              <p className="title mb-4">{t("formresponse_title")}</p>
              <p>{t("formresponse_content")}</p>
            </div>
            <Button className="w-full ">{t("tryout_button")}</Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Plans;
